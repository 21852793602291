export enum TenderDraftSubStatus {
  CONFIGURATION = "CONFIGURATION",
  TENDER_ADDRESS = "TENDER_ADDRESS",
  TENDER_FEE = "TENDER_FEE",
  TENDER_CRITICAL_DATE = "TENDER_CRITICAL_DATE",
  TENDER_DOCUMENT = "TENDER_DOCUMENT",
  TENDER_APPROVALS = "TENDER_APPROVALS",
  TENDER_TECHNICAL = "TENDER_TECHNICAL",
  TENDER_FINANCIAL = "TENDER_FINANCIAL",
  LOT = "LOT",
  COMPLETED = "COMPLETED"
}


//draft stages    
// CONFIGURATION("CONFIGURATION"),
//     TENDER_ADDRESS("TENDER_ADDRESS"),    
//     TENDER_FEE("TENDER_FEE"),   
//      TENDER_CRITICAL_DATE("TENDER_CRITICAL_DATE"),   
//       TENDER_DOCUMENT("TENDER_DOCUMENT"),   
//        TENDER_APPROVALS("TENDER_APPROVALS"),    
//        TENDER_TECHNICAL("TENDER_TECHNICAL"),    
//        TENDER_FINANCIAL("TENDER_FINANCIAL"),    
//        COMPLETED("COMPLETED");    
 