import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './layouts/common/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'Admin',
    // canActivate: [authGuard],
    loadChildren: () => import('./layouts/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'Auctions',
    loadChildren: () => import('./layouts/Auction/auction.module').then(m => m.AuctionModule)
  },
  {
    path: 'SuperAdmin',
    // canActivate: [authGuard],
    loadChildren: () => import('./layouts/super-admin/super-admin.module').then(m => m.SuperAdminModule)
  },
  {
    path: '',
    loadChildren: () => import('./layouts/landing/landing.module').then(m => m.LandingModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
