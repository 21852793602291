<div class="relative w-8 h-8 flex items-center justify-center">
  <!-- Background circle -->
  <svg class="w-full h-full" viewBox="0 0 32 32">
    <circle class="text-gray-300" stroke-width="4" stroke="currentColor" fill="transparent" r="14" cx="16" cy="16" />
    <!-- Progress circle -->
    <circle [ngClass]="strokeColor" [attr.stroke-dasharray]="circumference" [attr.stroke-dashoffset]="strokeDashoffset"
      stroke-width="4" stroke="currentColor" fill="transparent" r="14" cx="16" cy="16">
    </circle>
  </svg>
</div>

<!-- <div class="relative w-8 h-8 flex items-center justify-center"> -->
  <!-- Background circle -->
  <!-- <div class="absolute inset-0 rounded-full border-4 border-gray-300"></div> -->
  <!-- Dynamic progress circle -->
  <!-- <div class="absolute inset-0 rounded-full border-4 border-green-500 clip-half animate-spin-slow"> -->
  <!-- </div> -->
<!-- </div> -->